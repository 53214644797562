import React, { memo, useRef } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Form, Input, Radio, Select, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { ForkOutlined } from 'shared/ui/Icons';
import { useTranslate } from 'shared/lib/i18n';
import { useResultViewOptions } from '../../../lib/useResultViewOptions';
import { useAppSelector } from 'shared/model';
import { selectSlugs } from '../../../model/selectors';

const OPERATOR_OPTIONS = [
	{ value: 'equal', label: 'Equal' },
	{ value: 'not-equal', label: 'Not equal' },
	{ value: 'more', label: 'More' },
	{ value: 'less', label: 'Less' },
	{ value: 'contain', label: 'Contain' },
	{ value: 'not-contain', label: 'Not contain' }
];

const IfCallFieldsComponent = ({ action, title, actionsSlot, onChange, actionIndex, form, path }: TAppFormFields) => {
	const t = useTranslate();
	const resultViewOptions = useResultViewOptions();
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

	const slugs = useAppSelector(selectSlugs);

	const handleVariableInsert = (path: string[], variable: string, prompt: any) => {
		if (textAreaRef.current) {
			const { selectionStart, selectionEnd } = textAreaRef.current;
			const newPrompt = prompt
				? `${prompt.substring(0, selectionStart)}{{ ${variable} }}${prompt.substring(selectionEnd)}`
				: `{{ ${variable} }}`;
			form?.setFieldValue(path, newPrompt);
			onChange(path, newPrompt);
			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = selectionStart + variable.length + 6;
					textAreaRef.current.focus();
				}
			}, 0);
		}
	};

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium mb-5'>
				<div className='flex items-center gap-x-2.5'>
					<div className='bg-fill-tertiary rounded w-[20px] min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<ForkOutlined className='text-green-500' />
					</div>
					{title}
				</div>
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.SUCCESS}
				name={[...path, EAppFormFields.NEXT_SLUG, EAppFormFields.SUCCESS]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]?.[EAppFormFields.SUCCESS]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input
					onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG, EAppFormFields.SUCCESS], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={EAppFormFields.FAIL}
				name={[...path, EAppFormFields.NEXT_SLUG, EAppFormFields.FAIL]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]?.[EAppFormFields.FAIL]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG, EAppFormFields.FAIL], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('result_view')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('result_view.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.TYPE]}
				initialValue={action.data[EAppFormFields.TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
			>
				<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
					{resultViewOptions.map((option) => (
						<Radio key={`${option.label}_${option.value}`} value={option.value}>
							{option.label}
						</Radio>
					))}
				</Radio.Group>
			</Form.Item>
			<Form.Item
				label={<span>{t('value')}</span>}
				name={[...path, EAppFormFields.CONDITION, EAppFormFields.VALUE]}
				initialValue={action.data[EAppFormFields.CONDITION]?.[EAppFormFields.VALUE]}
				className='mb-2'
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},

					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 4 }}
					ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
					onChange={(e) => onChange([...path, EAppFormFields.CONDITION, EAppFormFields.VALUE], e.target.value)}
				/>
			</Form.Item>
			<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
				{slugs?.slice(0, actionIndex).map((slug) => (
					<div
						key={slug}
						className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
						onClick={() =>
							handleVariableInsert(
								[...path, EAppFormFields.CONDITION, EAppFormFields.VALUE],
								slug,
								action.data[EAppFormFields.CONDITION]?.[EAppFormFields.VALUE]
							)
						}
					>
						{slug}
					</div>
				))}
			</div>
			<Form.Item
				label={<span>{t('passed_value')}</span>}
				name={[...path, EAppFormFields.CONDITION, EAppFormFields.PASSED_VALUE]}
				initialValue={action.data[EAppFormFields.CONDITION]?.[EAppFormFields.PASSED_VALUE]}
				className='mb-2'
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},

					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 4 }}
					ref={(input) => (textAreaRef.current = input?.resizableTextArea?.textArea || null)}
					onChange={(e) => onChange([...path, EAppFormFields.CONDITION, EAppFormFields.PASSED_VALUE], e.target.value)}
				/>
			</Form.Item>
			<div className='flex flex-wrap gap-x-2.5 gap-y-1.5 mb-4'>
				{slugs?.slice(0, actionIndex).map((slug) => (
					<div
						key={slug}
						className='cursor-pointer text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'
						onClick={() =>
							handleVariableInsert(
								[...path, EAppFormFields.CONDITION, EAppFormFields.PASSED_VALUE],
								slug,
								action.data[EAppFormFields.CONDITION]?.[EAppFormFields.PASSED_VALUE]
							)
						}
					>
						{slug}
					</div>
				))}
			</div>
			<Form.Item
				label={
					<span>
						{t('operator')}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.CONDITION, EAppFormFields.OPERATOR]}
				initialValue={action.data[EAppFormFields.CONDITION]?.[EAppFormFields.OPERATOR]}
				rules={[{ required: true, message: t('error.required_field') }]}
			>
				<Select
					onChange={(value) => onChange([...path, EAppFormFields.CONDITION, EAppFormFields.OPERATOR], value)}
					options={OPERATOR_OPTIONS}
				></Select>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={action.data[EAppFormFields.AUTONEXT]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
		</div>
	);
};

export const IfCallFields = memo(IfCallFieldsComponent);
