import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { TInvite, TInviteFilterParams } from './types';

type TVaultsState = {
	invites: TInvite[];
	filter: TInviteFilterParams;
	page: number;
};

const initialState: TVaultsState = {
	invites: [],
	filter: {},
	page: 1
};

export const invitesSlice = createSlice({
	name: 'invites',
	initialState,
	reducers: {
		setInvitesList: (state, action: PayloadAction<TInvite[]>) => {
			state.invites = action.payload;
		},
		setInvitesPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		setInvitesFilter: (state, action: PayloadAction<TInviteFilterParams>) => {
			state.filter = { ...action.payload };
			state.page = 1;
		},
		updateInvitesList: (state, action: PayloadAction<TInvite[]>) => {
			state.invites = [...state.invites, ...action.payload];
		},
		deleteInvite: (state, action: PayloadAction<{ id: string }>) => {
			const invites = state.invites.filter((invite) => invite.id !== action.payload.id);
			state.invites = invites;
		}
	}
});

export const { setInvitesList, setInvitesPage, updateInvitesList, deleteInvite, setInvitesFilter } =
	invitesSlice.actions;
