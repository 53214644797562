import { useTranslate } from '@ayub-begimkulov/i18n';
import React, { FC } from 'react';
import { BALANCE_MULTIPLIER } from 'shared';
import { formatBalance } from 'shared/lib/formatBalance';

type TArtefactWrapperProps = {
	setArtefactRef: (index: number, el: HTMLDivElement | null) => void;
	index: number;
	artefactId: string;
	isDemo?: boolean;
	activeArtefactId: string | null;
	duration?: number;
	price?: number;
	children: React.ReactNode;
};

const getDuration = (seconds: number): string => {
	const totalSeconds = Math.round(seconds);
	const minutes = Math.floor(totalSeconds / 60);
	const remainingSeconds = totalSeconds % 60;

	return minutes > 0 ? `${minutes} мин ${remainingSeconds} сек` : `${remainingSeconds} сек`;
};

export const ArtefactWrapper = ({
	setArtefactRef,
	index,
	artefactId,
	isDemo,
	activeArtefactId,
	duration,
	price,
	children
}: TArtefactWrapperProps) => {
	const t = useTranslate();
	return (
		<div
			ref={(el) => setArtefactRef(index, el)}
			className={`grid gap-1 rounded-xl p-4 bg-gray-bg ${isDemo ? `demo-artefact-${index + 1}` : ''} ${
				activeArtefactId === artefactId ? '!bg-gray-bg-hover' : ''
			}`}
			data-index={artefactId}
		>
			{children}
			<div className='flex  justify-end gap-2 text-sm opacity-25'>
				{price && <span>{`${formatBalance(price * BALANCE_MULTIPLIER)} ${t('gptz')}`}</span>}
				{duration && <span>{getDuration(duration)}</span>}
			</div>
		</div>
	);
};
