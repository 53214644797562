import { combineReducers } from '@reduxjs/toolkit';
import { projectSlice } from 'entities/project';
import { threadSlice } from 'entities/thread';
import { baseApi } from 'shared/api/baseApi';
import { appsSlice } from 'entities/apps/model/slice';
import { appSettingsSlice } from 'entities/app-settings';
import { appSlice } from 'features/appFlowEditor';
import { userSlice } from 'entities/user';
import { vaultsSlice } from '../../entities/vaults';
import { invitesSlice } from '../../entities/invites';

export const rootReducer = combineReducers({
	[userSlice.name]: userSlice.reducer,
	[appSlice.name]: appSlice.reducer,
	[baseApi.reducerPath]: baseApi.reducer,
	[projectSlice.name]: projectSlice.reducer,
	[threadSlice.name]: threadSlice.reducer,
	[appsSlice.name]: appsSlice.reducer,
	[vaultsSlice.name]: vaultsSlice.reducer,
	[invitesSlice.name]: invitesSlice.reducer,
	[appSettingsSlice.name]: appSettingsSlice.reducer
});
