import React, { memo } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Button, Form, Input, Radio, Select, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { ApiCallActionIcon, DeleteOutlined, PlusOutlined } from 'shared/ui/Icons';
import { useTranslate } from 'shared/lib/i18n';
import { useResultViewOptions } from '../../../lib/useResultViewOptions';
import { useAppDispatch } from 'shared/model';
import { METHOD_OPTIONS } from '../../../model/constants';
import { addEmptyItemToArray, removeArrayItem } from '../../../model/slice';

const ApiCallFieldsComponent = ({ action, title, actionsSlot, onChange, path }: TAppFormFields) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const resultViewOptions = useResultViewOptions();

	const handleAddHeaderItem = () => {
		const emptyItem = Object.keys(action.data.headers?.[0] || {}).reduce(
			(acc, key) => {
				acc[key] = '';
				return acc;
			},
			{} as Record<string, any>
		);
		dispatch(addEmptyItemToArray({ actionId: action.id!, key: 'headers', item: emptyItem }));
	};

	const handleRemoveHeaderItem = (name: number) => {
		dispatch(removeArrayItem({ actionId: action.id!, key: 'headers', itemIndex: name }));
	};

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium mb-5'>
				<div className='flex items-center gap-x-2.5'>
					<div className='bg-fill-tertiary rounded w-[20px] min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<ApiCallActionIcon />
					</div>
					{title}
				</div>
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<span>
						{EAppFormFields.BLOCK_TYPE}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('result_view')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('result_view.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.TYPE]}
				initialValue={action.data[EAppFormFields.TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
			>
				<Radio.Group onChange={(e) => onChange([...path, EAppFormFields.TYPE], e.target.value)}>
					{resultViewOptions.map((option) => (
						<Radio key={`${option.label}_${option.value}`} value={option.value}>
							<span className='font-normal'>{option.label}</span>
						</Radio>
					))}
				</Radio.Group>
			</Form.Item>
			<Form.Item
				label={
					<span>
						{EAppFormFields.URL}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.URL]}
				initialValue={action.data[EAppFormFields.URL]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.URL], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<span>
						{t('method')}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.METHOD]}
				initialValue={action.data[EAppFormFields.METHOD]}
				rules={[{ required: true, message: t('error.required_field') }]}
			>
				<Select
					onChange={(value) => onChange([...path, EAppFormFields.METHOD], value)}
					options={METHOD_OPTIONS}
				></Select>
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BODY}
				name={[...path, EAppFormFields.BODY]}
				initialValue={action.data[EAppFormFields.BODY]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input.TextArea rows={4} onChange={(e) => onChange([...path, EAppFormFields.BODY], e.target.value)} />
			</Form.Item>
			<Form.List name={[...path, EAppFormFields.HEADERS]} initialValue={action.data.headers}>
				{(fields, { add, remove }) => {
					return (
						<div>
							<div className='mb-2 font-medium'>{t('headers')}</div>
							{fields.map(({ key, name, ...restField }, index) => {
								return (
									<div key={key} className='mb-4'>
										<div className='flex justify-between items-center mb-2.5'>
											<div>
												header {index + 1} <span className='ml-1 text-error'>*</span>
											</div>
											<DeleteOutlined
												className='text-error'
												onClick={() => {
													handleRemoveHeaderItem(name);
													remove(name);
												}}
											/>
										</div>
										<div key={key} className='flex flex-col grow gap-y-2.5'>
											<Form.Item
												{...restField}
												className='mb-0'
												name={[name, EAppFormFields.NAME]}
												rules={[
													{
														required: true,
														message: 'error.required_field'
													},
													{ min: 1, message: `${t('error.min_length')} 1` }
												]}
											>
												<Input
													placeholder={EAppFormFields.NAME}
													onChange={(e) =>
														onChange([...path, EAppFormFields.HEADERS, `${name}`, EAppFormFields.NAME], e.target.value)
													}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												className='mb-0'
												name={[name, EAppFormFields.VALUE]}
												rules={[
													{
														required: true,
														message: 'error.required_field'
													},
													{ min: 1, message: `${t('error.min_length')} 1` }
												]}
											>
												<Input
													placeholder={EAppFormFields.VALUE}
													onChange={(e) =>
														onChange([...path, EAppFormFields.HEADERS, `${name}`, EAppFormFields.VALUE], e.target.value)
													}
												/>
											</Form.Item>
										</div>
									</div>
								);
							})}
							<Form.Item>
								<Button
									type='dashed'
									className='flex items-center w-full text-gray-light hover:!text-gray-border-dark !border-gray-light hover:!border-gray-border-dark border-2 !bg-white !shadow-none'
									onClick={() => {
										add();
										handleAddHeaderItem();
									}}
									icon={<PlusOutlined />}
								/>
							</Form.Item>
						</div>
					);
				}}
			</Form.List>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={action.data[EAppFormFields.AUTONEXT]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('is_xml')}</span>}
				name={[...path, EAppFormFields.IS_XML]}
				initialValue={action.data[EAppFormFields.IS_XML]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_XML], e)} />
			</Form.Item>
			<Form.Item
				label={<span>{EAppFormFields.JSONATA}</span>}
				name={[...path, EAppFormFields.JSONATA]}
				initialValue={action.data[EAppFormFields.JSONATA]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input.TextArea rows={4} onChange={(e) => onChange([...path, EAppFormFields.JSONATA], e.target.value)} />
			</Form.Item>
		</div>
	);
};

export const ApiCallFields = memo(ApiCallFieldsComponent);
