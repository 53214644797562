import { TVault, TVaultTag } from '../model/types';
import { TCollection } from 'shared';

export type TVaultsDTO = TCollection<TVault>;
export type TVaultTagsDTO = TCollection<TVaultTag>;

export enum ECreateVaultFormFields {
	ID = 'id',
	NAME = 'name',
	TAGS = 'tags',
	DESCRIPTION = 'description',
	INSTRUCTIONS = 'instructions',
	TYPE = 'type',
	IS_PUBLIC = 'isPublic',
	VALUE = 'value',
	OPTIONS = 'options',
	AUTHOR = 'author',
	EMBEDDING_SETTINGS = 'embeddingSettings',
	CONTEXT_SIZE_IN_CHARS = 'contextSizeInChars',
	MODEL = 'model'
}

export type TCreateVaultForm = {
	[ECreateVaultFormFields.ID]: string;
	[ECreateVaultFormFields.NAME]: string;
	[ECreateVaultFormFields.TAGS]: string[];
	[ECreateVaultFormFields.TYPE]: string;
	[ECreateVaultFormFields.DESCRIPTION]?: string;
	[ECreateVaultFormFields.INSTRUCTIONS]?: string;
	[ECreateVaultFormFields.IS_PUBLIC]: boolean;
	[ECreateVaultFormFields.VALUE]: string;
	[ECreateVaultFormFields.OPTIONS]: string;
	[ECreateVaultFormFields.AUTHOR]: string;
	[ECreateVaultFormFields.EMBEDDING_SETTINGS]: {
		[ECreateVaultFormFields.MODEL]: string;
		[ECreateVaultFormFields.CONTEXT_SIZE_IN_CHARS]: number;
	};
};
