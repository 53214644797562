import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/model';
import { selectActions, selectEdges } from '../model/selectors';
import {
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	Connection,
	Edge,
	EdgeChange,
	getIncomers,
	getOutgoers,
	MarkerType,
	Node,
	NodeChange,
	NodeMouseHandler,
	OnConnectEnd,
	OnConnectStart,
	OnNodesDelete
} from 'reactflow';
import {
	setActions,
	setCurrentTab,
	setEdges,
	setInitialRegenerateIndex,
	setIsActionListChanged,
	updateActionData
} from '../model/slice';
import { generateId } from '../../../shared/lib';
import { FormInstance } from 'antd';
import { ENodeHandleVariants } from '../model/types';
import { XYPosition } from '@reactflow/core/dist/esm/types/utils';
import { generateCustomEdge, generateCustomEdgesList } from './helpers';

export const useAppActionsTree = (
	editAppForm?: FormInstance,
	screenToFlowPosition?: (position: XYPosition) => XYPosition
) => {
	const dispatch = useAppDispatch();
	const actions = useAppSelector(selectActions);
	const edges = useAppSelector(selectEdges);
	const [connectingNode, setConnectingNode] = useState<{ nodeId: string; handleId?: string } | null>(null);
	const onConnect = useCallback((connection: Connection) => {
		setConnectingNode(null);
		dispatch(setEdges(addEdge(connection, edges)));
	}, []);

	const onEdgesChange = (changes: EdgeChange[]) => {
		if (changes[0]?.type && ['select', 'remove'].includes(changes[0]?.type)) return;
		const newEdges = applyEdgeChanges(changes, edges);
		dispatch(setEdges(newEdges));
	};

	const onNodesChange = (changes: NodeChange[]) => {
		if (changes.find((node) => node.type !== 'remove')) {
			const newNodes = applyNodeChanges(changes, actions);
			dispatch(setActions(newNodes));
		}
	};

	const onConnectStart: OnConnectStart = useCallback((_, { nodeId, handleId, handleType }) => {
		if (nodeId) {
			setConnectingNode({ nodeId, handleId: handleId ?? undefined });
		}
	}, []);

	const onConnectEnd: OnConnectEnd = useCallback(
		(event) => {
			if (!(event instanceof MouseEvent) || !connectingNode?.nodeId) return;
			const sourceIndex = actions?.findIndex((action) => action.id === connectingNode?.nodeId);
			const sourceAction = actions[sourceIndex];
			const targetIsPane = (event.target as HTMLElement)?.classList.contains('react-flow__pane');
			if (targetIsPane) {
				const id = `${generateId().substring(0, 5)}`;
				const newAction: Node = {
					id,
					type: 'initial',
					selected: true,
					position: screenToFlowPosition?.({
						x: event.clientX,
						y: event.clientY
					}) ?? {
						x: event.clientX,
						y: event.clientY
					},
					data: {
						slug: id,
						type: 'static_text',
						isMapCallItem: connectingNode?.handleId === ENodeHandleVariants.ITEM_SLUG
					}
				};
				const newActions = actions.map((action) => ({ ...action, selected: false }));
				dispatch(setActions([...newActions, newAction]));
				dispatch(
					setEdges(
						edges.concat([
							generateCustomEdge({
								id: `${connectingNode?.nodeId}->${id}`,
								source: connectingNode?.nodeId,
								target: id,
								sourceHandle: connectingNode?.handleId
							})
						])
					)
				);
				const nextSlugValue =
					typeof sourceAction?.data.nextSlug === 'object' && connectingNode?.handleId
						? { ...sourceAction.data.nextSlug, [connectingNode?.handleId]: id }
						: id;
				if (connectingNode?.nodeId) {
					dispatch(
						updateActionData({
							actionId: connectingNode?.nodeId,
							path: [
								'actions',
								sourceIndex,
								connectingNode?.handleId === ENodeHandleVariants.ITEM_SLUG ? ENodeHandleVariants.ITEM_SLUG : 'nextSlug'
							].join('.'),
							value: nextSlugValue
						})
					);
				}
				editAppForm?.setFieldValue(
					[
						'actions',
						sourceIndex,
						connectingNode?.handleId === ENodeHandleVariants.ITEM_SLUG ? ENodeHandleVariants.ITEM_SLUG : 'nextSlug'
					],
					nextSlugValue
				);
			}
		},
		[actions, edges, connectingNode, editAppForm, screenToFlowPosition]
	);

	const onNodeClick: NodeMouseHandler = useCallback((event, node) => {
		dispatch(setCurrentTab('steps'));
	}, []);

	const findDependentNodesAndEdges = (
		nodeId: string,
		edges: Edge[],
		visited: Set<string> = new Set()
	): {
		nodes: string[];
		edges: Edge[];
	} => {
		if (visited.has(nodeId)) {
			return { nodes: [], edges: [] };
		}
		visited.add(nodeId);

		const outgoingEdges = edges.filter((edge) => edge.source === nodeId);

		const dependentNodeIds = outgoingEdges.map((edge) => edge.target);

		const subDependencies = dependentNodeIds.reduce<{
			nodes: string[];
			edges: Edge[];
		}>(
			(acc, dependentNodeId) => {
				const { nodes: subNodes, edges: subEdges } = findDependentNodesAndEdges(dependentNodeId, edges, visited);
				return {
					nodes: [...acc.nodes, dependentNodeId, ...subNodes],
					edges: [...acc.edges, ...subEdges]
				};
			},
			{ nodes: [], edges: [] }
		);

		return {
			nodes: [nodeId, ...subDependencies.nodes],
			edges: [...outgoingEdges, ...subDependencies.edges]
		};
	};

	const onNodesDelete: OnNodesDelete = useCallback(
		(deleted: Node[]) => {
			deleted.forEach((node) => {
				const incomers = getIncomers(node, actions, edges);
				if (node.type === 'if_call') {
					const { nodes: actionsToRemove, edges: edgesToRemove } = findDependentNodesAndEdges(node.id, edges);
					const updatedActions = actions
						.filter((action) => !actionsToRemove.includes(action.id))
						.map((action) => {
							if (action.id === incomers[0]?.id) {
								const updatedData = { ...action.data };
								updatedData.nextSlug = '';
								return {
									...action,
									data: { ...updatedData }
								};
							} else {
								return action;
							}
						});

					dispatch(setActions(updatedActions));
					dispatch(setEdges(generateCustomEdgesList(updatedActions)));
				} else {
					const outgoers = getOutgoers(node, actions, edges);

					const updatedActions = actions
						?.filter((action) => action.id !== node.id)
						?.filter((action) => action.id !== node.data.itemSlug)
						.map((action) => {
							const targetOutgoer = outgoers.find((outgoer) => outgoer.id === node.data.nextSlug);
							if (action.id === incomers[0]?.id && targetOutgoer) {
								const updatedData = { ...action.data };
								const TargetIfKey =
									typeof updatedData.nextSlug === 'object'
										? Object.entries(updatedData.nextSlug).find(([key, value]) => value === node.id)?.[0]
										: undefined;
								if (TargetIfKey) {
									updatedData.nextSlug = { ...updatedData.nextSlug, [TargetIfKey]: targetOutgoer.id };
								} else {
									updatedData.nextSlug = targetOutgoer.id;
								}
								return {
									...action,
									data: { ...updatedData }
								};
							} else if (action.id === incomers[0]?.id) {
								const updatedData = { ...action.data };
								const TargetIfKey =
									typeof updatedData.nextSlug === 'object'
										? Object.entries(updatedData.nextSlug).find(([key, value]) => value === node.id)?.[0]
										: undefined;

								if (TargetIfKey) {
									updatedData.nextSlug = { ...updatedData.nextSlug, [TargetIfKey]: '' };
								} else if (updatedData.itemSlug === node.id) {
									updatedData.itemSlug = '';
								} else {
									updatedData.nextSlug = '';
								}

								return {
									...action,
									data: { ...updatedData }
								};
							} else {
								return action;
							}
						});

					dispatch(setActions(updatedActions));
					dispatch(setEdges(generateCustomEdgesList(updatedActions)));
				}
				dispatch(setInitialRegenerateIndex(1));
				applyNodeChanges([{ id: node.id, type: 'remove' }], actions);
				dispatch(setIsActionListChanged(true));
			});
		},
		[actions, edges]
	);

	return {
		actions,
		edges,
		onConnect,
		onConnectStart,
		onEdgesChange,
		onNodesChange,
		onConnectEnd,
		onNodeClick,
		onNodesDelete
	};
};
