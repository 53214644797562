import { baseApi } from 'shared/api/baseApi';
import { ORG_INVITES_TAG } from 'shared/api/tags';
import { TInvitesDTO } from './types';
import { TInvite } from '../model/types';
import qs from 'qs';
import { INVITES_PER_PAGE } from '../model/constants';
import { deleteInvite, setInvitesList, updateInvitesList } from '../model/slice';

export const organizationsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getInvitesList: build.query<TInvitesDTO, { search?: string; page: number; invitesPerPage?: number }>({
			query: ({ search, page, invitesPerPage }) => {
				const query: any = {
					email: {
						contains: search
					}
				};

				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: query,
						limit: invitesPerPage ?? INVITES_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `orgs_invites${stringifiedQuery}`
				};
			},
			onQueryStarted: async (payload, { dispatch, getState, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					if (payload.page !== 1) {
						dispatch(updateInvitesList(data.docs));
					} else {
						dispatch(setInvitesList(data.docs));
					}
				} catch (err) {
					console.error(err);
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [ORG_INVITES_TAG]
		}),
		getInvite: build.query<Pick<TInvite, 'organization' | 'email'>, string>({
			query: (organizationInviteToken) => ({
				url: `orgs/invites/${organizationInviteToken}`
			})
		}),
		createInvite: build.mutation<TInvite, string>({
			query: (email) => {
				return {
					url: `orgs_invites`,
					method: 'POST',
					data: {
						email
					}
				};
			}
		}),
		deleteInvite: build.mutation<TInvite, string>({
			query: (inviteId) => {
				return {
					url: `orgs_invites/${inviteId}`,
					method: 'DELETE'
				};
			},
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(deleteInvite({ id }));
				} catch (err) {
					console.error(err);
				}
			},
			invalidatesTags: [ORG_INVITES_TAG]
		})
	})
});

export const {
	useGetInvitesListQuery,
	useLazyGetInvitesListQuery,
	useGetInviteQuery,
	useCreateInviteMutation,
	useDeleteInviteMutation
} = organizationsApi;
