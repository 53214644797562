import React, { useState, FC, memo, useEffect, ReactNode } from 'react';

import { TCardItem } from '../../../../entities/project';
import { ArtefactOption } from './ArtefactOption';
import { Button } from 'antd';
import { EditOutlinedIcon } from '../../../../shared/ui/Icons';
import { useTranslate } from '../../../../shared/lib/i18n';

interface IArtefactOptionsProps {
	data: TCardItem[];
	id: string;
	name: string;
	description?: string;
	onSelectionChange: (selectedItems: TCardItem[] | undefined) => void;
	blocked: boolean;
	regenerateBtn?: ReactNode;
	reviewBtn?: ReactNode;
}
const ArtefactOptionsComponent: FC<IArtefactOptionsProps> = ({
	id,
	data,
	onSelectionChange,
	blocked,
	name,
	description,
	regenerateBtn,
	reviewBtn
}) => {
	const t = useTranslate();
	const [cards, setCards] = useState(data);
	const [isDisabled, setIsDisabled] = useState(true);
	const [selectedCount, setSelectedCount] = useState(0);

	const handleEdit = () => {
		setIsDisabled((prev) => !prev);
	};
	const handleCheckboxChange = (title: string) => {
		setCards((prevCards) => {
			const updatedCards = prevCards.map((card) => (card.title === title ? { ...card, checked: !card.checked } : card));
			return updatedCards;
		});
	};

	const handleSave = () => {
		setIsDisabled((prev) => !prev);
		onSelectionChange(cards);
	};

	const handleCancel = () => {
		setIsDisabled((prev) => !prev);
		setCards(data);
	};

	useEffect(() => {
		const selectedCards = cards.filter((card) => card.checked);
		setSelectedCount(selectedCards.length);
	}, [cards]);
	return (
		<>
			<div className='flex justify-between items-start'>
				<div>
					<p className='text-sm opacity-50'>{name}</p>
					{description && <p className='text-sm mb-7 opacity-25'>{description}</p>}
				</div>
				<div className='choice-artefact-controls min-h-[14px]'>
					{regenerateBtn}
					<Button
						type='text'
						shape='circle'
						icon={<EditOutlinedIcon className='!text-xl' />}
						onClick={handleEdit}
						disabled={!isDisabled || blocked}
					/>
					{isDisabled ? <>{reviewBtn}</> : <span className='opacity-40'>{t('edit_mode')}</span>}
				</div>
			</div>
			<div className='flex items-start flex-wrap gap-4'>
				{cards.map((item) => (
					<ArtefactOption item={item} onCheckboxChange={handleCheckboxChange} key={item.title} disabled={isDisabled} />
				))}
			</div>
			<p className='mt-3 opacity-25'>{`Выбрано: ${selectedCount}`}</p>
			{!isDisabled && (
				<div className='flex gap-x-3 mt-3'>
					<Button type='primary' size='large' onClick={handleSave}>
						{t('save_and_next')}
					</Button>
					<Button size='large' type='text' onClick={() => handleCancel()}>
						{t('cancel')}
					</Button>
				</div>
			)}
		</>
	);
};

export const ArtefactOptions = memo(ArtefactOptionsComponent);
