import React, { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { PlusOutlined } from 'shared/ui/Icons';
import { PopoverProps } from 'antd';
import { useAppDispatch } from 'shared/model';
import { changeActionType } from '../../model/slice';
import { TBlockTypes } from '../../model/types';
import cn from 'classnames';
import { CustomPopover } from 'shared/ui';
import { ActionsList } from '../ActionsList';

const InitialNodeComponent = (props: NodeProps) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(true);
	const { selected, data } = props;
	useEffect(() => {
		if (selected) {
			setOpen(true);
		}
	}, [selected]);

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	const selectActionType = async (value: TBlockTypes) => {
		setOpen(false);
		await dispatch(changeActionType({ id: props.id, newType: value }));
	};

	return (
		<div
			className={cn('flex items-center justify-center border border-dashed border-gray-border rounded p-px bg-white', {
				'border-primary-active border-2 !border-solid': selected || open
			})}
		>
			<CustomPopover
				destroyTooltipOnHide={true}
				trigger='click'
				open={open}
				content={<ActionsList handleSelectAction={selectActionType} isMapCallItem={data.isMapCallItem} />}
				onOpenChange={handleOpenChange}
				arrow={false}
			>
				<div onClick={(e) => e.preventDefault()} className='min-w-[20px] min-h-[20px] flex items-center justify-center'>
					<PlusOutlined className='opacity-25 text-[8px]' />
				</div>
			</CustomPopover>
			<Handle type='target' position={Position.Top} className='w-16 !bg-teal-500 h-auto' isConnectable={false} />
		</div>
	);
};

export const InitialNode = memo(InitialNodeComponent);
